const TEXT = {
  general: {
    all_rights_reserved: "All Rights Reserved",
    close: "Close",
    mac_input_message: "Please input your mac address",
    mac_input_valid_message: "Mac address is valid",
    mac_input_not_valid_message: "Mac address is not valid",
    mac_input_placeholder: "Mac address",
    login: "Login",
  },

  pages: {
    home: {
      page_name: "Home",
      header_title: "Pay once and activate your device forever for 8.90 Euro",
      header_second_title:
        "Try Wise ipTV Player on Samsung & LG TV for 5 Days FREE",
      header_description:
        "Important! We do not provide any content with this App and you must have playlist to use it. Please make sure you have playlist before purchase .",
      header_activate_app_button: "Activate app",
      header_upload_playlist_button: "Upload playlist",

      section_one_title: "Launch Your Wise ipTV Player for 5 Days FREE",
      section_one_description:
        "It is the best solution on the market for better experiencing your playlists of TV channels, films, and series, and is available in the stores of Samsung & LG TVs",

      section_two_title: "Watch Live TV with comfort",
      section_two_description:
        "Our good organized UI will bring you a new level of watching live tv programs. Pick a channel, Watch, Save to favorites, and the app will start suggesting to you what you like to watch.",

      section_three_title: "Find content fastet",
      section_three_description:
        "A single place to search for your Movies, Tv shows, or LiveTV. Search and start instantly your watch session without 0 lose of your time.",

      news_section_title: "Latest News",
      news_section_view_more: "View more",
      platforms_section_soon: "Coming soon",
      platforms_section_avilable: "Available",
      "Download": "Download",
      "Download_for_free":"Download for free"
    },

    activation: {
      page_name: "Activation",

      choose_plan_text: "Choose a plan that fits your needs best",
      direct_pay: "Direct pay",
      gift_code: "Gift code",
      monthly: "Monthly",
      yearly: "Yearly",
      one_time: "One time",
      agree_with_all: "Agree with all",
      agree_with_all_description:
        "You must agree to our privacy policy and terms of use before activation",
      activate_for_qa_description:
        "Activatee tv device by voucher code for Beta testers and LG / Samsung QA team",
      activate_qa_button_text: "Activate",
      payments_methods_disabled: "No payment method activated",

      gift_code_input_placeholder: "Enter your GIFT code",
      gift_code_input_message: "Please input your GIFT code",
      or: "Or",

      success_message: "Your activation was successfully done!",
      error_message: "Invalid code",

      button_text: "Activate",

      qa_modal_title: "Activate",
      qa_modal_voucher_code_input_placeholder: "Enter voucher code",
      qa_modal_voucher_code_input_message: "Please input voucher code",
      qa_modal_success_message: "Activate",
      qa_modal_button_title: "Activate",
      stripe_button_text: "Pay By Card",
    },

    voucher_code: {
      page_name: "Voucher Code",
      qa_modal_title: "Activate",
      qa_modal_voucher_code_input_message: "Please input voucher code",
      qa_modal_voucher_code_input_placeholder: "Enter voucher code",
      qa_modal_button_title: "Activate",
      qa_modal_success_message: "Activate",
    },
    simple_activation: {
      title:
        "By activating you will have access to the Media player for LifeTime",
      warning_text: "ATTENTION! THE APP IS ONLY A MEDIA PLAYER. WE DO NOT PROVIDE ANY TYPE OF CONTENT!",
      agree_with_all: "Privacy Policy",
      mac_adress_modal_title:
        "You can find your MAC address in the application on TV on the settings right bottom corner",
      "or": "or"
    },

    upload_playlist: {
      page_name: "Upload playlist",
      title: "Upload Playlist",
      description:
        "Choose how you want to load your playlist into the application",
      url: "Url",
      xtream_account: "Xtream account",
      mac_address: "Mac address",
      playlist_name: "Playlist name",
      playlist_url: "Playlist url",
      "playlist_epg_url": "Playlist EPG url",
      host: "Host",
      username: "Username",
      password: "Password",
      url_button_title: "Upload",
      xtream_button_title: "upload by xtream",
      playlist_name_message: "Please input playlist name",
      playlist_url_message: "Please input playlist url",
      host_message: "Please input host",
      username_message: "Please input username",
      password_message: "Please input password",
      success_message: "Playlist successfully added",
      other: "Other M3u8",
      file: "File",
      protect_playlist: "Protect Playlist",
      set_pin: "Set PIN",
      confirm_pin: "Confirm PIN",
      confirm_password_input_message: "Please confirm your password!",
      confirm_password_input_message_2: "The two passwords that you entered do not match!",
      error_message: "Name must be maximum 30 characters",
    },

    reseller: {
      page_name: "Reseller",
    },

    register: {
      page_name: "Register",
      register: "Reseller Register",
      reseller_login: "Reseller Login",
      title: "Become a partner",
      subtitle: "More info about partners",
      company_input_placeholder: "Company name",
      company_input_message: "Please input company name",
      name_input_placeholder: "Name",
      name_input_message: "Please input name",
      surname_input_placeholder: "Surname",
      surname_input_message: "Please input surname",
      email_input_placeholder: "Email",
      email_input_message: "Please input valid email",
      password_input_placeholder: "Enter your password",
      password_message: "Please input your password",
      password_length_message: "Password must be minimum 8 characters",
      country_select_placeholder: "Select country",
      country_select_message: "Please select country",
      address_input_placeholder: "Address",
      address_input_message: "Please input address",
      phone_input_message: "Please input phone",
      choose_partner_type: "Please choose partner type",
      reseller: "Reseller",
      referral: "Referral",
      accept_text: "Accept privacy policy and usage",
      agree_text: "Agree with all",
      step_one_button_title: "Next",
      step_two_button_title: "Register",
      select_reseller_message: "Member must be a Reseller or Referral",
      register_success_message: "Registred",
    },

    news: {
      page_name: "News",
      title: "Latest News",
    },

    pricing: {
      page_name: "Pricing",
      title: "Pricing",
      activate_app: "Activate app",
      reseller_packaging: "Reseller Packages",
      become_a_reseller: "Become a reseller",
      alert:"For purchasing one of the packages you need to go BECOME A RESELLER page and register.",
    },

    current_news: {
      subtitle: "You May Be Interested Also",
    },

    contact_us: {
      page_name: "Contact Us",
      title: "Contact Us",
      description: `Please Send your Mac Address for any App issue you have for faster replay from us " Thank You "`,
      location_title: "Our location",
      phone_title: "Phone number",
      email_title: "Email address",
      title_input_placeholder: "Your title",
      title_input_message: "Please input your title",
      email_input_placeholder: "Your email",
      email_input_message: "Please input valid email",
      message_input_placeholder: "Your message",
      message_input_message: "Please input message",
      button_title: "Send message",
      submition_success_message: "Your support message was submitted",
    },

    terms_of_use: {
      page_name: "Terms of use",
      content: "",
    },

    terms_of_online_sale: {
      page_name: "Terms of Online Sale",
      content: "",
    },

    privacy: {
      title: "PRIVACY POLICY",
      page_name:"Privacy Policy",
      content: "",
    },

    payment_error: {
      page_name: "Payment error",

      message:
        "Please check with your payment provider about the status of the account or contact our support",
    },

    payment_success: {
      page_name: "Payment success",

      message:
        "Your payment was done successfully. The app must be activated automatically if it's not please restart the app or contact support",
    },

    login: {
      page_name: "Login",
      title: "Login",
      mac_input_placeholder: "Device Mac",
      mac_input_message: "Please input device mac",
      code_input_placeholder: "OTP",
      code_input_message: "Please input OTP",
      enter_code: "Enter code",
      device_key: "Device Key",
      code_description:
        "You will get an OTP on your TV after filling in the MAC",
      device_key_description: "You can find device key on TV  settings section",
      device_key_input_placeholder: "Please input Device key",
      device_key_input_message: "Please input device key",
      button_text: "LOGIN",
      log_out: 'Log Out',
    },

    login_2: {
      page_name: "Manage playlist",
      title: "Manage playlist",
      mac: "MAC address",
      model: "Device Model",
      date: "Expire date",
      delete_playlist_modal_title: "Are you sure you want to delete?",
      add_playlist_modal_title: "Add new playlist",
      edit_playlist_modal_title: "Edit playlist",
      url: "Url",
      xtream_account: "Xtream account",
      mac_address: "Mac address",
      playlist_name: "Playlist name",
      playlist_url: "Playlist url",
      host: "Host",
      username: "Username",
      password: "Password",
      url_button_title: "Upload",
      playlist_name_message: "Please input playlist name",
      playlist_url_message: "Please input playlist url",
      password_input_message: "Please input your password!",
      confirm_password_input_message: "Please confirm your password!",
      confirm_password_input_message_2: "The two passwords that you entered do not match!",
      host_message: "Please input host",
      username_message: "Please input username",
      password_message: "Please input password",
      "Enter your PIN": "Enter your PIN",
      set_pin: "Set PIN",
      confirm_pin: "Confirm PIN",
      other_m3u8: "Other M3u8",
      file: "File",
      SAVE: "SAVE",
      my_profile: "My Profile",
      my_playlist: "My Playlists",
      protected_playlist: "The playlist is protected",
      m3u8: "Please select m3u8 file",
      all: " All",
      hide: " Hide",
      lock: "Lock",
      protect_playlist: "Protect Playlist",
      package_name: 'Package Name',
      payment_date: "Payment date",
      package_type: 'Package type',
      price: 'Price',
      transaction_id: 'Transaction ID',
      method: 'Method',
      status: 'Status',
      log_out: 'Log Out',
      "playlist_epg_url": "Playlist EPG url",
      'Enter':'Enter'
    },

    multiple_device_login: {
      page_name: "Multiple device login",
      title: "Login",
      login: "Login",
      logout: "Logout",
      button_text: "LOGIN",
      register_link: "Create Account",
      email_placeholder: "Email",
      password_placeholder: "Password",
      registration: "Registration",
      password_message: "Please input your password",
      email_message: "Please input your E-mail!",
      email_validation_message: 'The input is not valid E-mail!',
      devices: "Devices",
      add_device: "Add device",
      code_description:
        "You will get an OTP on your TV after filling in the MAC",
      device_key_description: "You can find device key on TV  settings section",
      device_key_input_placeholder: "Please input Device key",
      device_key_input_message: "Please input device key",
      code_input_placeholder: "OTP",
      code_input_message: "Please input OTP",
      delete_playlist_modal_title: "Are you sure you want to delete?",
      enter_code: "Enter code",
      device_key: "Device Key",
      mac_modal_desc: "Enter device mac and activate it",
      mac_modal_warning_text: 'Make sure that you have already downloaded application to you device',
      login_by_mac: "Login by mac",
    },

    playlist: {
      button_text: "Add Playlists",
      logout_button_text: "LOGOUT",
      title: "Manage playlist",
      url: "Url",
      file: "File",
      xtream_account: "Xtream account",
      mac_address: "Mac address",
      playlist_name: "Playlist name",
      playlist_url: "Playlist url",
      host: "Host",
      username: "Username",
      password: "Password",
      url_button_title: "Upload",
      playlist_name_message: "Please input playlist name",
      playlist_url_message: "Please input playlist url",
      playlist_file_message: "Please select m3u8 file!",
      host_message: "Please input host",
      username_message: "Please input username",
      password_message: "Please input password",
      success_message: "Playlist successfully added",
      add_playlist_modal_title: "Add new playlist",
      edit_playlist_modal_title: "Edit playlist",
      delete_playlist_modal_title: "Are you sure you want to delete?",
      SAVE: "SAVE",
      Upload: "Upload",
      "Enter your PIN": "Enter your PIN",
      set_pin: "Set PIN",
      confirm_pin: "Confirm PIN",
      other_m3u8: "Other M3u8",
      "playlist_epg_url": "Playlist EPG url",
      protect_playlist: "Protect Playlist",
      'Enter':'Enter'
    },

    chat: {
      page_name: "Chat",
      chat_start_text: "We are online and ready to help!",
      title_one: "Connecting...",
      title_two: "Admin",
      message_input_placeholder: "Enter Message",
    },

    installation_guide: {
      page_name: "Installation Guide",
      title: "App Installation Guide",
      step: "step",
      step_1_text: "Enim nunc faucibus a pellentesque sit amet.",
      step_2_text: "Sed lectus vestibulum mattis ullamcorper dolor.",
      step_3_text: "Enim nunc faucibus a pellentesque sit amet.",
      step_4_text: "Enim nunc faucibus a pellentesque sit amet.",
      step_5_text: "Enim nunc faucibus a pellentesque sit amet.",
      step_6_text: "Enim nunc faucibus a pellentesque sit amet.",
    },

    applications: {
      page_name: "Applications",
    },
  },
};

export default TEXT;
