import { useState } from "react";
import { bindActionCreators } from "redux";

import { useDispatch, useSelector } from "react-redux";

import { Radio } from 'antd';

import { actions } from "../store";

import EditorDrawer from "./components/EditorDrawer";
import ColorComponent from "./components/ColorComponent";
import BlockComponent from "./components/BlockComponent";
import ImageComponent from "./components/ImageComponent";
import SwitchComponent from "./SwitchComponent";

export default function GlobalEditor({ }) {
    const dispatch = useDispatch();

    const { setWebSettings } = bindActionCreators(actions, dispatch);

    const { webSettings } = useSelector((store) => store.globalState);

    const [inputStyle, setInputStyle] = useState('rectangle');

    const changeLogo = (image) => {
        let configs = { ...webSettings };

        configs.globals.logo = image;

        setWebSettings(configs);
    };

    const onChangeSize = (size) => {
        let configs = { ...webSettings };

        const root = document.documentElement;

        root.style.setProperty("--logo-size", `${size}px`);

        configs.globals.logo_size = +size;

        setWebSettings(configs);
    };

    const changeActiveColor = (color) => {
        let configs = { ...webSettings };

        configs.globals.active_color = color;

        setWebSettings(configs);
        const root = document.documentElement;
        root.style.setProperty("--active", color);
    };

    const changeHoverColor = (color) => {
        let configs = { ...webSettings };

        configs.globals.hover_color = color;

        setWebSettings(configs);
        const root = document.documentElement;
        root.style.setProperty("--hover-color", color);
    };

    const changeBackgroundColor = (color) => {
        let configs = { ...webSettings };

        configs.globals.background_color = color;

        setWebSettings(configs);

        const root = document.documentElement;

        root.style.setProperty("--background-color", color);
    };

    const changeTextColor = (color) => {
        let configs = { ...webSettings };

        configs.globals.text_color = color;

        setWebSettings(configs);

        const root = document.documentElement;

        root.style.setProperty("--text-color", color);
    };

    const changeTitleColor = (color) => {
        let configs = { ...webSettings };

        configs.globals.title_color = color;

        setWebSettings(configs);

        const root = document.documentElement;

        root.style.setProperty("--title-color", color);
    };

    const changeNavbarBackgroundColor = (color) => {
        let configs = { ...webSettings };

        configs.navbar.background_color = color;

        setWebSettings(configs);

        const root = document.documentElement;

        root.style.setProperty("--navbar-background-color", color);
    };

    const changeNavbarTextColor = (color) => {
        let configs = { ...webSettings };

        configs.navbar.text_color = color;

        setWebSettings(configs);

        const root = document.documentElement;

        root.style.setProperty("--navbar-text-color", color);
    };

    const changeButtonsTextColor = (color) => {
        let configs = { ...webSettings };

        configs.globals.buttons_text_color = color;

        setWebSettings(configs);

        const root = document.documentElement;

        root.style.setProperty("--button-text-color", color);
    };

    return (
        <EditorDrawer title={"Global settings"} navbar>
            <ImageComponent
                size={webSettings?.globals?.logo_size}
                onChangeSize={onChangeSize}
                title={"Logo"}
                defaultImage={webSettings?.globals?.logo}
                updateImage={(e) => changeLogo(e)}
                deleteImage={() => changeLogo("")}
            />

            <BlockComponent title="Colors">
                <ColorComponent
                    defaultColor={webSettings?.globals?.active_color}
                    text="Active color"
                    callback={changeActiveColor}
                />

                <ColorComponent
                    defaultColor={webSettings?.globals?.hover_color}
                    text="Hover color"
                    callback={changeHoverColor}
                />

                <ColorComponent
                    defaultColor={webSettings?.globals?.text_color}
                    text="Text color"
                    callback={changeTextColor}
                />

                <ColorComponent
                    defaultColor={webSettings?.globals?.title_color}
                    text="Title color"
                    callback={changeTitleColor}
                />

                <ColorComponent
                    defaultColor={webSettings?.globals?.buttons_text_color}
                    text="Button text color"
                    callback={changeButtonsTextColor}
                />

                <ColorComponent
                    defaultColor={webSettings?.globals?.background_color}
                    text="Background color"
                    callback={changeBackgroundColor}
                />

                <ColorComponent
                    defaultColor={webSettings?.navbar?.text_color}
                    text="Navbar text color"
                    callback={changeNavbarTextColor}
                />

                <ColorComponent
                    defaultColor={webSettings?.navbar?.background_color}
                    text="Navbar background color"
                    callback={changeNavbarBackgroundColor}
                />
            </BlockComponent>

            <BlockComponent title="Inputs border style" >
                <Radio.Group
                    onChange={(e) => {
                        let configs = { ...webSettings };

                        configs.globals.inputBorderStyle = e.target.value;
                
                        setWebSettings(configs);
                    }}
                    defaultValue={webSettings?.globals?.inputBorderStyle}
                >
                    <Radio value={'rectangle'}>Rectangle</Radio><br/>
                    <Radio value={'underline'}>Underline</Radio>
                </Radio.Group>
            </BlockComponent>
        </EditorDrawer>
    );
}
