import style from "../style/card.module.scss";
import classNames from "classnames";
const Card = ({
  bg,
  title,
  text,
  image,
  reverse = true,
  imageStyle,
  iconSize,
}) => {
  return (
    <div className={bg ? `${style["card"]} ${style["bg"]}` : style["card"]}>
      <div
        className={classNames(style["container"],{[style["reverse"]]:!reverse})
        }
      >
         <div dir="auto" className={style["desc"]}>
          <h1>{title}</h1>
          <p>{text}</p>
        </div>
        <div style={{ textAlign: "center" }}>
          <img
            src={image}
            style={{ ...imageStyle }}
            width={`${iconSize}px`}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Card;
