import { useSelector } from "react-redux";

import TEXT from "../../config/text";
import useTranslation from "../../hooks/useTranslation";

import style from "./style/contact.module.scss";

export default function ContactUsInfo() {
    const translation = useTranslation();

    const { webSettings, appInfo } = useSelector((state) => state.globalState);

    const { phone, location, email } = webSettings?.pages?.contact_us;

    const handleText = (text) => {
        if (translation?.pages?.contact_us?.[text]) {
            return translation?.pages?.contact_us?.[text];
        }

        return TEXT?.pages?.contact_us?.[text];
    };

    return (
        <div className={style["contact-us_info"]}>
            <div className={style["info"]}>
                <h1 className={style["title"]}>{handleText("title")}</h1>
            </div>
            <p className={style["description"]}>{handleText("description")}</p>
            <ul className={style["contact-list"]}>
                {location?.enabled && appInfo?.location_name && (
                    <a href={appInfo?.location_url} target="_blank" rel="noreferrer">
                        <img src={location?.icon} alt="location" className={style["icon"]} />
                        <div className={style["list-info"]}>
                            <h3>{handleText("location_title")}</h3>
                            {appInfo?.location_name}
                        </div>
                    </a>
                )}

                {phone?.enabled && appInfo?.phone && (
                    <a href={`tel:${appInfo?.phone}`} target="_blank">
                        <img src={phone?.icon} alt="phone numbeer" className={style["icon"]} />
                        <div className={style["list-info"]}>
                            <h3>{handleText("phone_title")}</h3>
                            <p>{appInfo?.phone}</p>
                        </div>
                    </a>
                )}

                {email?.enabled && appInfo?.email && (
                    <a href={`mailto:${appInfo?.email}`} target="_blank">
                        <img src={email?.icon} alt="email" className={style["icon"]} />
                        <div className={style["list-info"]}>
                            <h3>{handleText("email_title")}</h3>
                            <p>{appInfo?.email}</p>
                        </div>
                    </a>
                )}
            </ul>
        </div>
    );
}
